import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import withAuthorization from "../components/Session/withAuthorization";

import CodeChat from "../components/Chat/Code";
import GeneralChat from "../components/Chat/General";
import ContributorChat from "../components/Chat/Contributors";
import RequestChat from "../components/Chat/Request";
import FeedbackChat from "../components/Chat/Feedback";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class HomePage extends Component {
  render() {
    return (
      <div>
        <Helmet title="Enlight Community" />

        <h1
          className="chat-title"
          ref={messages => {
            this.messages = messages;
          }}
        >
          <span className="title">enlight /</span> community{" "}
          <label>(beta)</label>
          <br />
        </h1>

        <Tabs selectedTabPanelClassName="selected" forceRenderTabPanel={true}>
          <div className="channels">
            <TabList>
              <Tab>
                <li>
                  <label>#</label> general{" "}
                </li>
              </Tab>

              <Tab>
                <li>
                  <label>#</label> code{" "}
                </li>
              </Tab>
              <Tab>
                <li>
                  <label>#</label> contributors{" "}
                </li>
              </Tab>
              <Tab>
                <li>
                  <label>#</label> request{" "}
                </li>
              </Tab>
              <Tab>
                <li>
                  <label>#</label> feedback{" "}
                </li>
              </Tab>
            </TabList>
          </div>

          <TabPanel className="panel">
            <GeneralChat />
          </TabPanel>
          <TabPanel className="panel">
            <CodeChat />
          </TabPanel>
          <TabPanel className="panel">
            <ContributorChat />
          </TabPanel>
          <TabPanel className="panel">
            <RequestChat />
          </TabPanel>
          <TabPanel className="panel">
            <FeedbackChat />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

HomePage.contextTypes = {
  authUser: PropTypes.object
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(HomePage);
