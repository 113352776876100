import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { db } from "api/firebase";
import firebase from "firebase/app";
import "firebase/auth";
import { Link } from "gatsby";
import Markdown from "react-remarkable-with-plugins";
import Spinner from "react-spinkit";

class GeneralChat extends Component {
  constructor() {
    super();

    this.state = {
      value: "",
      messages: [],
      users: {},
      timestamp: "",
      sort_key: "",
      loading: true
    };

    this.onAddMessage = this.onAddMessage.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
  }

  componentDidMount() {
    var el = this.refs.messagesList;
    el.scrollTop = el.scrollHeight;
  }

  componentDidUpdate() {
    var el = this.refs.messagesList;
    el.scrollTop = el.scrollHeight;
  }

  componentWillMount() {
    db.onceGetUsers().then(snapshot =>
      this.setState(() => ({ users: snapshot.val(), loading: false }))
    );

    db.onMessageAdded(snapshot => {
      this.setState(prevState => ({
        messages: [...prevState.messages, snapshot.val()]
      }));
    });
  }

  onChangeMessage(event) {
    const { value } = event.target;
    this.setState(() => ({ value }));
  }

  onAddMessage(event) {
    event.preventDefault();

    const authUser = firebase.auth().currentUser.uid;
    const { value } = this.state;

    var date = new Date();
    this.state.timestamp = date.toISOString();
    this.state.sort_key = -1 * new Date(this.state.timestamp).getTime();

    const { timestamp, sort_key } = this.state;
    console.log(sort_key);

    db.doCreateMessage(authUser, value, timestamp, sort_key);

    this.setState(() => ({ value: "", timestamp: "", sort_key: "" }));
  }

  render() {
    const { messages, users, value, loading } = this.state;

    if (loading) {
      var spinner = (
        <div className="center">
          <Spinner name="ball-scale-multiple" color="#438cee" />
        </div>
      );
    } else {
      spinner = <div />;
    }

    return (
      <div>
        <div className="chat" id="general">
          <div className="messages" ref="messagesList">
            <h3> # general </h3>

            {spinner}

            {messages.map((message, i) => {
              if (
                i > 0 &&
                users[messages[i - 1].userId].id === users[message.userId].id
              )
                return (
                  <MessageFollowing
                    key={i}
                    message={message}
                    user={users[message.userId]}
                    timestamp={message.timestamp}
                  />
                );
              else
                return (
                  <Message
                    key={i}
                    message={message}
                    user={users[message.userId]}
                    timestamp={message.timestamp}
                  />
                );
            })}
          </div>

          <form onSubmit={this.onAddMessage}>
            <input
              className="message"
              type="text"
              value={value}
              onChange={this.onChangeMessage}
              placeholder="Your message here..."
            />
          </form>
        </div>
      </div>
    );
  }
}

const Message = ({ message, user }) => {
  const messenger = user ? `${user.username}` : "...";
  const id = user ? `${user.id}` : "...";
  const imageURL = user.photoURL || "/img/avatar.png";

  function convertDate(date) {
    var localDate = new Date(date);
    localDate = localDate.toLocaleString();
    return localDate;
  }

  function highlight(string) {
    return string.replace(/\B\@([\w\-]+)/gim, function(str) {
      return (
        "<a href='/user?id=" +
        str.substring(1) +
        "'>" +
        "<span class='mention'>" +
        str +
        "</span></a>"
      );
    });
  }

  return (
    <div className="a-message">
      <span className="info">
        <Link to={`/user?id=${id}`}>
          <p className="messenger">
            <Link to={`/user?id=${id}`}>
              <img className="profile-image-comment-message" src={imageURL} />
            </Link>
            {messenger}
            <span> @{id}</span>
          </p>
        </Link>
        <p className="timestamp">{convertDate(message.timestamp)}</p>
      </span>
      <div className="text">
        <Markdown options={{ html: true, linkify: true }}>
          {highlight(message.text)}
        </Markdown>
      </div>
    </div>
  );
};

const MessageFollowing = ({ message, user }) => {
  const messenger = user ? `${user.username}` : "...";
  const id = user ? `${user.id}` : "...";

  function convertDate(date) {
    var localDate = new Date(date);
    localDate = localDate.toLocaleString();
    return localDate;
  }

  function highlight(string) {
    return string.replace(/\B\@([\w\-]+)/gim, function(str) {
      return (
        "<a href='/user?id=" +
        str.substring(1) +
        "'>" +
        "<span class='mention'>" +
        str +
        "</span></a>"
      );
    });
  }
  return (
    <div className="text">
      <Markdown options={{ html: true, linkify: true }}>
        {highlight(message.text)}
      </Markdown>
    </div>
  );
};

export default GeneralChat;
